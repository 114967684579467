// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener("turbolinks:load", function() {
  
  jQuery('.tra_programmes').hide().insertAfter('.tra_header');

  jQuery('.tra_header__toggle').click(function(e){
    e.preventDefault();
    jQuery('.tra_programmes').slideToggle();
    jQuery(this).toggleClass('tra_header__toggle--active');
  });

  jQuery('.nav_toggle, .main_nav_overlay, .main_nav__close').click(function(){
    jQuery('.main_nav').toggleClass('main_nav--active');
    jQuery('.main_nav_overlay').fadeToggle();
    jQuery('.layout__header, .layout__main, .layout__footer').toggleClass('blur');
  });

  jQuery('.flash__close').click(function(){
    jQuery(this).parent().hide();
  });

  // Quiz

  jQuery('.quiz__option').click(function(){
    jQuery(this).siblings('.quiz__option').slideToggle();
    jQuery(this).toggleClass('quiz__option--highlight');
    jQuery(this).parent().toggleClass('quiz--answered');

    var target = $(this).parent().next('div');

    target.scrollIntoView();


  });
  
  jQuery('.quiz_results__option p').hide();

  jQuery('.quiz_results__option h3').click(function(){
    jQuery(this).siblings('p').slideToggle();
  });

});